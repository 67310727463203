// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import loading from './loading'

import users from '@src/views/users/store/reducer'
import languages from '@src/views/languages/store/reducer'
import roles from '@src/views/roles/store/reducer'
import assets from '@src/views/assets/store/reducer'
import vouchers from '@src/views/vouchers/store/reducer'
import account from '@src/views/account/store/reducer'
import locations from '@src/views/locations/store/reducer'
import tests from '@src/views/tests/store/reducer'
import testTypes from '@src/views/test-types/store/reducer'
import campaigns from '@src/views/campaigns/store/reducer'
import calendarEvents from '@src/views/calendar-events/store/reducer'
import orders from '@src/views/orders/store/reducer'

const rootReducer = combineReducers({
  auth,

  navbar,
  layout,

  loading,

  users,
  languages,
  roles,
  assets,
  vouchers,
  account,
  locations,
  tests,
  testTypes,
  campaigns,
  calendarEvents,
  orders,
})

export default rootReducer
