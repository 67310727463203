const initialState = {
  data: [],
  selected: null,
  middlewares: [],
  logs: [],
  response: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }
    case 'EDIT_ACCOUNT':
      return { 
        ...state, 
        selected: action.selected,
        middlewares: action.middlewares,
      }
    case 'UPDATE_ACCOUNT':
      return { 
        ...state,
        response: action.response 
      }
    case 'UPDATE_ACCOUNT_PASSWORD':
      return {
        ...state,
        response: action.response 
      }
    case 'GET_ACCOUNT_LOGS':
      return { 
        ...state, 
        logs: action.logs,
      }
    case 'DELETE_ACCOUNT':
      return { 
        ...state,  
        response: action.response
      }
    default:
      return { ...state }
  }
}
export default reducer