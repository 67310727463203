const initialState = {
  items: []
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      let items = state.items.filter(i=>i.loader !== action.loader)

      return { ...state, items: [...items, { loader: action.loader, active: action.active }] }
    default:
      return state
  }
}

export default layoutReducer
