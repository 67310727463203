const initialState = {
  data: [],
  selected: null,
  middlewares: {},
  logs: [],
  response: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }        
    case 'GET_USER_DATA':
    case 'GET_USER_CONFIRMED':
    case 'GET_USER_UNCONFIRMED':
    case 'GET_USER_SUBSCRIBED_ACTIVE':
    case 'GET_USER_SUBSCRIBED_INACTIVE':
    case 'GET_USER_NONSUBSCRIBED_ACTIVE':
    case 'GET_USER_NONSUBSCRIBED_INACTIVE':
      return {
        ...state,
        data: action.data,
      } 
    case 'NEW_USER':
      return { 
        ...state,
        middlewares: action.middlewares,
      }    
    case 'CREATE_USER':
      return { 
        ...state,
        response: action.response 
      }            
    case 'EDIT_USER':
      return { 
        ...state, 
        selected: action.selected,
        middlewares: action.middlewares,
      }
    case 'UPDATE_USER':
      return { 
        ...state,
        response: action.response 
      }    
    case 'UPDATE_USER_PASSWORD':
      return {
        ...state,
        response: action.response 
      }
    case 'GET_USER_LOGS':
      return { 
        ...state, 
        logs: action.logs,
      }      
    case 'DELETE_USER':
      return { 
        ...state,  
        response: action.response
      }
    default:
      return { ...state }
  }
}
export default reducer